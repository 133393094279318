/* eslint-disable @typescript-eslint/no-explicit-any */
import { env } from '../env'
;((): void => {
  const i = 'cioanalytics'
  const analytics: any = ((window as any)[i] = (window as any)[i] || [])

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console &&
        console.error &&
        console.error('Snippet included twice.')
    } else {
      analytics.invoked = true
      analytics.methods = []

      analytics.factory = (e: string) => {
        return (...args: any[]) => {
          args.unshift(e)
          analytics.push(args)
          return analytics
        }
      }

      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e]
        analytics[key] = analytics.factory(key)
      }

      analytics.load = (key: string, e: any): void => {
        const t = document.createElement('script')
        t.type = 'text/javascript'
        t.async = true
        t.setAttribute('data-global-customerio-analytics-key', i)
        t.src = `https://cdp.customer.io/v1/analytics-js/snippet/${key}/analytics.min.js`
        const n = document.getElementsByTagName('script')[0]
        n.parentNode?.insertBefore(t, n)
        analytics._writeKey = key
        analytics._loadOptions = e
      }

      analytics.SNIPPET_VERSION = '4.15.3'
      analytics.load(env.VITE_CUSTOMERIO_API_KEY, {
        integrations: {
          'Customer.io In-App Plugin': {
            siteId: env.VITE_CUSTOMERIO_SITE_ID,
          },
        },
      })
    }
  }
})()
